// src/components/PaymentOptions.js
import React from 'react';

const PaymentOptions = () => {
    return (
        <div className="payment-options">
            <div className="option"><img src="/images/payment/sber.webp" alt="Sber" /></div>
            <div className="option"><img src="/images/payment/bnb.webp" alt="BNB" /></div>
            <div className="option"><img src="/images/payment/btc.webp" alt="BTC" /></div>
            <div className="option"><img src="/images/payment/crypto.webp" alt="Crypto" /></div>
            <div className="option"><img src="/images/payment/eth.webp" alt="ETH" /></div>
            <div className="option"><img src="/images/payment/ltc.webp" alt="LTC" /></div>
            <div className="option"><img src="/images/payment/SkinsBack.webp" alt="SkinsBack" /></div>
            <div className="option"><img src="/images/payment/visa-mc-mir_1.png" alt="Visa/MasterCard" /></div>
            <div className="option"><img src="/images/payment/usdt_trc20.webp" alt="USDT" /></div>
        </div>
    );
};

export default PaymentOptions;
