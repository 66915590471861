// src/components/Payment.js
import React from 'react';
import Header from './components/Header';
import LineContainer from './components/LineContainer';
import WinnersSlider from './components/WinnersSlider';
import PaymentOptions from './components/PaymentOptions';
import PaymentContainer from './components/PaymentContainer';
import MainImage from './components/MainImage';
import Overlay from './components/Overlay';
import Footer from './components/Footer';

import './styles/GenericStyles/footer.css'; // Importing your styles
import './styles/pageStyles/payment/payment.css'; // Importing your styles
import './styles/GenericStyles/slider.css'; // Importing your styles
import './styles/GenericStyles/button.css'; // Importing your styles
import './styles/GenericStyles/headline.css'; // Importing your styles
import './styles/GenericStyles/menu.css'; // Importing your styles
import './styles/GenericStyles/main.css'; // Importing your styles

const Payment = ({ slideImages }) => {
    return (
        <div>
            <MainImage />
            <Overlay />
            <Header />
            <WinnersSlider slides={slideImages}/>
            <LineContainer title="Пополнение баланса" />
             <div className="payment-content">
                <PaymentOptions />
                <PaymentContainer />
            </div>
            <Footer />
        </div>
    );
};

export default Payment;
