import React from 'react';

const HeroSection = () => {
    return (
        <section id="hero" className="hero-section">
            <img className="logo-image" src="images/dota2.webp" alt="" />
            <h1 className="title">DOTA 2</h1>
            <span className="main-p">
                Ready to unlock some legendary loot? Our exclusive Dota 2 cases are packed with top-tier skins, rare items, and in-game bonuses.
                Join thousands of players and take your gameplay to the next level! Login – Connect your account securely and get instant access to free cases.
                Claim – Spin the case for your chance to win skins, heroes, and more. Win Big – Luck is on your side! Open legendary items that will turn heads in every match
            </span>
        </section>
    );
};

export default HeroSection;
