import React from 'react';

const LineContainer = ({ title }) => {
    return (
        <div className="line-container">
            <div className="line left-line"></div>
            <span className="line-text">{title}</span>
            <div className="line right-line"></div>
        </div>
    );
};

export default LineContainer;
