import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext'; // Assuming you have an AuthContext to manage user data

const UserProfile = () => {
    const { user } = useAuth();  // Accessing user data from the AuthContext
    const [inventory, setInventory] = useState([]);
    const [userData, setUserData] = useState({
        steamId: '', 
        username: '', 
        avatar: '', 
    });

    useEffect(() => {
        // Ensure we have the user's data
        if (user && user.id) {
            console.log("Fetching inventory for steamId:", user.id);  // Debug log for steamId
            setUserData({
                steamId: user.id,
                username: user.username,
                avatar: user.avatar
            });
    
            // Fetch the user's Steam inventory once the user is logged in and steamId is available
            const fetchSteamInventory = async () => {
                console.log("Calling fetchSteamInventory function");  // Debug log for the function call
    
                try {
                    const response = await fetch(`/get-steam-inventory?steam_id=${user.id}`);
                    const data = await response.json();
    
                    console.log("Fetched inventory data:", data);  // Debug log for the response
    
                    if (data.error) {
                        console.error(data.error);
                    } else {
                        setInventory(data);  // Set the fetched inventory to state
                    }
                } catch (error) {
                    console.error("Error fetching Steam inventory:", error);
                }
            };
    
            fetchSteamInventory();
        }
    }, [user]); // Re-run the effect whenever the user changes (e.g., after login)
    
    useEffect(() => {
        console.log("Inventory state:", inventory);  // Debug log for inventory state
    }, [inventory]);  // Log whenever inventory is updated

    return (
        <div className="user-profile-section">
            <div className="user-profile-card">
                <div className="user-card">
                    <div className="user-avatar">
                        <img src={userData.avatar} alt={userData.username} className="avatar-img" />
                    </div>
                    <div className="user-details">
                        <div className="user-name">{userData.username}</div>
                        <button className="level-up-btn">Сгенерировать уровень</button>
                        <div className="progress-container">
                            <div className="progress-bar" style={{ width: '50%' }}>70%</div>
                        </div>
                        <div className="experience-info">
                            <span>0/50 XP</span>
                            <span className="how-it-works">Как это работает?</span>
                        </div>
                    </div>
                </div>
                <div className="user-balances">
                    <div className="balance">0 ₽</div>
                    <div className="balance">0 Монеты</div>
                    <button className="fill-ballance">Пополнить баланс</button>
                </div>
                <div className="account-info">
                    <div className="account-status">Аккаунт не подтвержден</div>
                    <button className="confirm-account-btn">Подтвердить аккаунт</button>
                    <button className="partner-program-btn">Партнерская программа...</button>
                </div>
            </div>
            <div className="tab-buttons">
                <button className="tab-btn active">Новые 2</button>
                <button className="tab-btn">Старые 2</button>
            </div>

            <div className="items-display">
                {/* Display each item from the user's Steam inventory */}
                {inventory.length > 0 ? (
                    inventory.map((item, index) => (
                        <div className="item" key={index}>
                            <div className="image-container">
                                <img src={item.icon_url} alt={item.name} />
                                <div className="info">
                                    <p>{item.name}</p>
                                </div>
                            </div>
                            <h3>{item.name}</h3>
                        </div>
                    ))
                ) : (
                    <p>No inventory items found. Please check if the Steam inventory is accessible.</p>
                )}
            </div>
        </div>
    );
};

export default UserProfile;
