import React from 'react';

const HowToPlay = ({ cases}) => {
    return (
        <section className="how-to-play-section">
        <div className="steps">
            {cases.map((caseItem) => (
                <div className="step" key={caseItem.id}>
                    <img src={caseItem.image} alt={caseItem.name} />
                    <div className="info-box">
                        <h3>{caseItem.name}</h3>
                        <p className="common-button">{caseItem.price}</p>
                    </div>
                </div>
            ))}
        </div>
    </section>
    );
};

export default HowToPlay;
