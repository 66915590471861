import React, { useEffect, useRef, useState } from 'react';
import '../styles/GenericStyles/slider.css'; // Assuming you have your styles in this CSS file

const WinnersSlider = ({ slides }) => {
    const [currentSlides, setCurrentSlides] = useState(slides);
    const sliderRef = useRef(null);
    const slideWidth = 80; // Width of each slide
    let slideInterval;

    // Function to slide the items
    const slideItems = () => {
        if (sliderRef.current) {
            // Move the first slide to the end of the array
            setCurrentSlides((prevSlides) => {
                const [firstSlide, ...rest] = prevSlides;
                return [...rest, firstSlide];
            });
        }
    };

    // Function to start the sliding interval
    const startSlider = () => {
        slideInterval = setInterval(slideItems, 2000); // Change slide every 2 seconds
    };

    // Function to stop the sliding interval
    const stopSlider = () => {
        clearInterval(slideInterval);
    };

    useEffect(() => {
        startSlider();

        // Clean up the interval on component unmount
        return () => {
            clearInterval(slideInterval);
        };
    }, []);

    return (
        <div
            className="winners"
            onMouseEnter={stopSlider}
            onMouseLeave={startSlider}
        >
            <div className="slider" ref={sliderRef} style={{ display: 'flex' }}>
                {currentSlides.map((slide, index) => (
                    <div className="slide-top" key={index}>
                        <div className="slider-content">
                            <img src={slide} alt={`Slide ${index + 1}`} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WinnersSlider;
