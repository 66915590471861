import React from 'react';
import MainImage from './components/MainImage';
import Overlay from './components/Overlay';
import Header from './components/Header';
import WinnersSlider from './components/WinnersSlider';
import HeroSection from './components/HeroSection';
import LineContainer from './components/LineContainer';
import HowToPlay from './components/HowToPlay';
import Footer from './components/Footer';
import './styles/GenericStyles/footer.css'; // Importing your styles
import './styles/pageStyles/cases/cases.css'; // Importing your styles
import './styles/GenericStyles/slider.css'; // Importing your styles
import './styles/GenericStyles/button.css'; // Importing your styles
import './styles/GenericStyles/headline.css'; // Importing your styles
import './styles/GenericStyles/menu.css'; // Importing your styles
import './styles/GenericStyles/main.css'; // Importing your styles

const App = () => {
  const slideImages = [
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
  ]; 
  
  const casesData = [
    {
        id: 1,
        image: '/images/cases/case_1.webp',
        name: 'Case 1',
        price: '400 RUR',
    },
    {
        id: 2,
        image: '/images/cases/case_2.webp',
        name: 'Case 2',
        price: '450 RUR',
    },
    {
        id: 3,
        image: '/images/cases/case_3.webp',
        name: 'Case 3',
        price: '500 RUR',
    },
    {
        id: 4,
        image: '/images/cases/case_1.webp',
        name: 'Case 1',
        price: '400 RUR',
    },
    {
        id: 5,
        image: '/images/cases/case_2.webp',
        name: 'Case 2',
        price: '450 RUR',
    },
    {
        id: 6,
        image: '/images/cases/case_3.webp',
        name: 'Case 3',
        price: '500 RUR',
    },
    {
        id: 7,
        image: '/images/cases/case_1.webp',
        name: 'Case 1',
        price: '400 RUR',
    },
    {
        id: 8,
        image: '/images/cases/case_2.webp',
        name: 'Case 2',
        price: '450 RUR',
    },
    {
        id: 9,
        image: '/images/cases/case_3.webp',
        name: 'Case 3',
        price: '500 RUR',
    },
    {
        id: 10,
        image: '/images/cases/case_1.webp',
        name: 'Case 1',
        price: '400 RUR',
    },
    {
        id: 11,
        image: '/images/cases/case_2.webp',
        name: 'Case 2',
        price: '450 RUR',
    },
    {
        id: 12,
        image: '/images/cases/case_3.webp',
        name: 'Case 3',
        price: '500 RUR',
    },
    {
        id: 13,
        image: '/images/cases/case_1.webp',
        name: 'Case 1',
        price: '400 RUR',
    },
    {
        id: 14,
        image: '/images/cases/case_2.webp',
        name: 'Case 2',
        price: '450 RUR',
    },
    {
        id: 15,
        image: '/images/cases/case_3.webp',
        name: 'Case 3',
        price: '500 RUR',
    },
    {
        id: 16,
        image: '/images/cases/case_1.webp',
        name: 'Case 1',
        price: '400 RUR',
    },
    {
        id: 17,
        image: '/images/cases/case_2.webp',
        name: 'Case 2',
        price: '450 RUR',
    },
    {
        id: 18,
        image: '/images/cases/case_3.webp',
        name: 'Case 3',
        price: '500 RUR',
    },
    {
        id: 18,
        image: '/images/cases/case_1.webp',
        name: 'Case 1',
        price: '400 RUR',
    },
    {
        id: 19,
        image: '/images/cases/case_2.webp',
        name: 'Case 2',
        price: '450 RUR',
    },
    {
        id: 20,
        image: '/images/cases/case_3.webp',
        name: 'Case 3',
        price: '500 RUR',
    },
    // Add more cases as needed
];
    return (
        <div>
            <MainImage />
            <Overlay />
            <Header />
            <WinnersSlider slides={slideImages}/>
            <HeroSection />
            <LineContainer title="All CASES" />
            <HowToPlay cases = {casesData} />
            <Footer />
        </div>
    );
};

export default App;
