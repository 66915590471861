// src/components/Profile.js
import React from 'react';
import MainImage from './components/MainImage';
import Overlay from './components/Overlay';
import Header from './components/Header';
import WinnersSlider from './components/WinnersSlider';
import UserProfile from './components/UserProfile';
import Footer from './components/Footer';
import LineContainer from './components/LineContainer';

import './styles/GenericStyles/footer.css'; // Importing your styles
import './styles/pageStyles/profile/profile.css'; // Importing your styles
import './styles/GenericStyles/slider.css'; // Importing your styles
import './styles/GenericStyles/button.css'; // Importing your styles
import './styles/GenericStyles/headline.css'; // Importing your styles
import './styles/GenericStyles/menu.css'; // Importing your styles
import './styles/GenericStyles/main.css'; // Importing your styles

const Profile = ({slideImages}) => {
    return (
        <div>
            <MainImage />
            <Overlay />
            <Header />
            <WinnersSlider slides={slideImages} />
            <LineContainer title={"Аккаунт"}/>
            <UserProfile />
            <Footer />
        </div>
    );
};

export default Profile;
