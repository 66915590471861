import React from 'react';
import CustomSelect from './CustomSelect'; // Adjust the path based on your structure

const Payment = () => {
    return (
        <div className="payment-container">
            <h2>Хочу оплатить</h2>
            <div className="amount-selection">
                <input type="number" placeholder="1000 ₽" />
                <CustomSelect />
            </div>
            <input className="promo-input" placeholder="Промокод" />

            <div className="quick-select">
                <button>350</button>
                <button>500</button>
                <button>1000</button>
                <button>1500</button>
                <button>3000</button>
            </div>

            <div className="bonus-slider">
                <label>Бонус</label>
                <div className="slider">
                    <span>+5%</span>
                    <input type="range" min="0" max="5000" defaultValue="1000" />
                    <span>+15%</span>
                </div>
            </div>

            <h2>Получу</h2>
            <div className="total-amount">
                <span>1050.00 ₽</span>
            </div>

            <div className="bonus-info">
                <div className="bonus-box">
                    <p>+5% к пополнению</p>
                </div>
                <div className="bonus-details">
                    <p>Увеличить сумму пополнения до 2500 ₽ и получить бонус к пополнению 10%</p>
                </div>
            </div>

            <div className="agreement">
                <input type="checkbox" id="agreement" />
                <label htmlFor="agreement">Я принимаю <a href="#">пользовательское соглашение</a></label>
            </div>

            <button className="pay-button">Оплатить 1000 ₽</button>
        </div>
    );
};

export default Payment;
