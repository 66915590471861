import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext'; // Import the AuthProvider
import Home from './Home';
import Cases from './Cases';
import Payment from './Payment';
import Profile from './Profile';
import './styles/GenericStyles/footer.css'; // Importing your styles
import './styles/pageStyles/index/index.css'; // Importing your styles
import './styles/GenericStyles/slider.css'; // Importing your styles
import './styles/GenericStyles/button.css'; // Importing your styles
import './styles/GenericStyles/headline.css'; // Importing your styles
import './styles/GenericStyles/menu.css'; // Importing your styles
import './styles/GenericStyles/main.css'; // Importing your styles
import './styles/GenericStyles/LoginModal.css'; // Importing your styles

const App = () => {
  const slideImages = [
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
    '/images/cases/case_1.webp',
    '/images/cases/case_2.webp',
    '/images/cases/case_3.webp',
    '/images/cases/case_4.webp',
    '/images/cases/case_5.webp',
  ]; 
  
  const casesData = [
    {
        id: 1,
        image: '/images/cases/case_1.webp',
        name: 'Case 1',
        price: '400 RUR',
    },
    {
        id: 2,
        image: '/images/cases/case_2.webp',
        name: 'Case 2',
        price: '450 RUR',
    },
    {
        id: 3,
        image: '/images/cases/case_3.webp',
        name: 'Case 3',
        price: '500 RUR',
    },
    {
        id: 1,
        image: '/images/cases/case_1.webp',
        name: 'Case 1',
        price: '400 RUR',
    },
    {
        id: 2,
        image: '/images/cases/case_2.webp',
        name: 'Case 2',
        price: '450 RUR',
    },
    {
        id: 3,
        image: '/images/cases/case_3.webp',
        name: 'Case 3',
        price: '500 RUR',
    },
    {
        id: 1,
        image: '/images/cases/case_1.webp',
        name: 'Case 1',
        price: '400 RUR',
    },
    {
        id: 2,
        image: '/images/cases/case_2.webp',
        name: 'Case 2',
        price: '450 RUR',
    },
    {
        id: 3,
        image: '/images/cases/case_3.webp',
        name: 'Case 3',
        price: '500 RUR',
    },
    {
        id: 1,
        image: '/images/cases/case_1.webp',
        name: 'Case 1',
        price: '400 RUR',
    },
    {
        id: 2,
        image: '/images/cases/case_2.webp',
        name: 'Case 2',
        price: '450 RUR',
    },
    {
        id: 3,
        image: '/images/cases/case_3.webp',
        name: 'Case 3',
        price: '500 RUR',
    },
    {
        id: 1,
        image: '/images/cases/case_1.webp',
        name: 'Case 1',
        price: '400 RUR',
    },
    {
        id: 2,
        image: '/images/cases/case_2.webp',
        name: 'Case 2',
        price: '450 RUR',
    },
    {
        id: 3,
        image: '/images/cases/case_3.webp',
        name: 'Case 3',
        price: '500 RUR',
    },
    {
        id: 1,
        image: '/images/cases/case_1.webp',
        name: 'Case 1',
        price: '400 RUR',
    },
    {
        id: 2,
        image: '/images/cases/case_2.webp',
        name: 'Case 2',
        price: '450 RUR',
    },
    {
        id: 3,
        image: '/images/cases/case_3.webp',
        name: 'Case 3',
        price: '500 RUR',
    },
    {
        id: 1,
        image: '/images/cases/case_1.webp',
        name: 'Case 1',
        price: '400 RUR',
    },
    {
        id: 2,
        image: '/images/cases/case_2.webp',
        name: 'Case 2',
        price: '450 RUR',
    },
    {
        id: 3,
        image: '/images/cases/case_3.webp',
        name: 'Case 3',
        price: '500 RUR',
    },
    // Add more cases as needed
];
return (
    <AuthProvider>
        <Router>
            <Routes>
                <Route path="/" element={<Home slideImages={slideImages} casesData={casesData} />} />
                <Route path="/cases" element={<Cases />} />
                <Route path="/Payment" element={<Payment slideImages={slideImages} />} />
                <Route path="/Profile" element={<Profile slideImages={slideImages}/>} />
            </Routes>
        </Router>
    </AuthProvider>
);
};

export default App;
