import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LoginModal from './LoginModal'; // Import the LoginModal component
import { useAuth } from '../contexts/AuthContext'; // Correct import of useAuth

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the URL search params
  const { isLoggedIn, user, login, logout } = useAuth(); // Get authentication state and functions
  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  // Capture steam_id, username, and avatar from the URL query parameters after login
  const handleLoginFromURL = () => {
    const params = new URLSearchParams(location.search);
    const id = params.get('id');
    const username = params.get('username');
    const avatar = params.get('avatar');

    if (id && username && avatar) {
      // Update the logged-in state with user data
      login({
        id,
        username,
        avatar
      });
    }
  };

  useEffect(() => {
    // Handle login redirect and update user state
    handleLoginFromURL();  // Check for steam_id, username, and avatar in the URL when it changes
  }, [location.search]);  // When the search params change (i.e., after redirect)

  const handleHomeClick = () => {
    navigate('/'); // Redirect to the home route
  };

  const handleCasesClick = () => {
    navigate('/cases'); // Redirect to the cases route
  };

  const handlePaymentClick = () => {
    navigate('/payment'); // Redirect to the payment route
  };

  const handleProfileClick = () => {
    navigate('/profile'); // Redirect to the profile route
  };

  // Function to show login modal
  const handleLoginClick = () => {
    setShowModal(true); // Show the login modal
  };

  // Close the modal
  const closeModal = () => {
    setShowModal(false); // Close the modal
  };

  // Handle logout
  const handleLogout = () => {
    logout(); // Set login state to false
    navigate('/'); // Redirect to home after logging out
  };

  window.onscroll = function () {
    var header = document.querySelector('header');
    if (window.scrollY > 50) {  // If the page is scrolled more than 50px
      header.classList.add('scrolled');
    } else {
      header.classList.remove('scrolled');
    }
  };

  return (
    <header className="content-header">
      <div className="logo">
        <img className="main-logo" src="/images/dota2.webp" alt="Game Logo" />
      </div>
      <div className="menu-container">
        <a href="#!" onClick={handleHomeClick}>
          <div className="common-button">
            <img src="/images/menu/home.webp" alt="Home" />
          </div>
        </a>
        <a href="#!" onClick={handleCasesClick}>
          <div className="common-button">
            <img src="/images/menu/cases.webp" alt="Cases" />
          </div>
        </a>
        <div className="common-button">
          <img src="/images/menu/contracts.webp" alt="Contracts" />
        </div>
        <div className="common-button">
          <img src="/images/menu/upgrades.webp" alt="Upgrades" />
        </div>
      </div>

      <div className="profile-section">
        {isLoggedIn ? (
          <>
            {/* Profile View */}
            <button className="balance-button" onClick={handlePaymentClick}>
              Пополнить баланс
            </button>
            <div className="avatar">
              <img src={user.avatar} alt={user.username} />
            </div>
            <div className="user-info">
              <div className="username">
                <a href="#!" onClick={handleProfileClick}>{user.username}</a>
              </div>
              <div className="balances">
                <div>10000 ₽</div>
                {/* <div>10000 ₽</div> */}
              </div>
            </div>
              <div className="logout-image" onClick={handleLogout}>
                <img src="images/logout.png" alt="Logout" />
              </div>
          </>
        ) : (
          // Login Button
          <button className="common-button-login" onClick={handleLoginClick}>
            <span>ВОЙТИ</span>
          </button>
        )}
      </div>

      {/* Show Login Modal if showModal is true */}
      {showModal && <LoginModal closeModal={closeModal} />}
    </header>
  );
};

export default Header;
