import React, { useState, useEffect, useRef } from 'react';

const CustomSelect = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState('RU');
    const options = [
        { value: 'RU', label: 'RU' },
        { value: 'UA', label: 'UA' },
        { value: 'KZ', label: 'KZ' },
    ];
    const selectBoxRef = useRef(null);

    const toggleDropdown = () => {
        setIsOpen((prev) => !prev);
    };

    const handleOptionClick = (value) => {
        setSelectedValue(value);
        setIsOpen(false);
    };

    const handleClickOutside = (event) => {
        if (selectBoxRef.current && !selectBoxRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className="custom-select" ref={selectBoxRef}>
            <div className="select-box" onClick={toggleDropdown}>
                <span className="selected">{selectedValue}</span>
                <div className={`options-container ${isOpen ? 'show' : ''}`}>
                    {options.map((option) => (
                        <div
                            key={option.value}
                            className="country-option"
                            onClick={() => handleOptionClick(option.value)}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CustomSelect;
