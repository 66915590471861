import React from 'react';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-top">
                    <div className="footer-logo">
                        <img className="main-logo" src="images/dota2.webp" alt="Logo" />
                    </div>
                    <div className="social-media">
                        <span>Мы в соц. сетях</span>
                        <div className="social-icons">
                            <a href="https://vk.com" target="_blank"><img src="images/VK Logo White.svg" alt="VK" /></a>
                            <a href="https://telegram.org" target="_blank"><img src="images/tg.svg" alt="Telegram" /></a>
                        </div>
                    </div>
                </div>
                <div className="footer-stats">
                    <div className="stat">
                        <span className="stat-number">500,958,175</span>
                        <span className="stat-label">открыто кейсов</span>
                    </div>
                    <div className="stat">
                        <span className="stat-number">11,634,266</span>
                        <span className="stat-label">игроков</span>
                    </div>
                    <div className="stat">
                        <span className="stat-number">715,099</span>
                        <span className="stat-label">батлов</span>
                    </div>
                    <div className="stat">
                        <span className="stat-number">12,515,165</span>
                        <span className="stat-label">контрактов</span>
                    </div>
                    <div className="stat">
                        <span className="stat-number">8,820,900</span>
                        <span className="stat-label">апгрейдов</span>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="footer-links">
                        <a href="#">Пользовательское соглашение</a>
                        <a href="#">Контакты</a>
                        <a href="#">Соглашение о приватности</a>
                        <a href="#">Купить голду</a>
                        <a href="#">Скачать приложение</a>
                    </div>
                </div>
                <div className="footer-contact">
                    <div className="footer-email">
                        <span>yourmail@domain.com</span>
                    </div>
                    <div className="footer-partnership">
                        <button className="btn-partnership">Сотрудничество</button>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
