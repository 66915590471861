import React, { useState } from 'react';

// Define the LoginModal component
const LoginModal = ({ closeModal, onLoginSuccess }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    // Function to handle Steam login
    const handleSteamLogin = async () => {
        setIsLoading(true);
        setError('');

        try {
            // Step 1: Redirect to the backend for Steam login
            window.location.href = 'https://opencase123.ru/steam-login'; // Backend URL for Steam login
        } catch (error) {
            setError('An error occurred while trying to log in with Steam');
            console.error('Login failed', error);
        }
    };
    // Function to handle VK login
    const handleVKLogin = async () => {
        setIsLoading(true);
        setError('');

        try {
            // Step 1: Redirect to the backend for VK login
            window.location.href = 'https://opencase123.ru/vk-login'; // Backend URL for VK login

        } catch (error) {
            setError('An error occurred while trying to log in with VK');
            console.error('Login failed', error);
        }
    };

    // Function to handle Google login
    const handleGoogleLogin = async () => {
        setIsLoading(true);
        setError('');

        // Simulate Google login and get an authentication token
        const googleToken = 'GOOGLE_TOKEN_HERE'; // Replace with actual Google login token

        try {
            const response = await fetch('https://opencase123.ru/google-login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ google_token: googleToken }),
            });

            const data = await response.json();

            if (response.ok) {
                onLoginSuccess(data.user_data);
                closeModal();
            } else {
                setError(data.error || 'Failed to login with Google');
            }
        } catch (error) {
            setError('An error occurred while trying to log in with Google');
            console.error('Login failed', error);
        }

        setIsLoading(false);
    };

    return (
        <div className="modal-overlay" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={closeModal}>
                    ×
                </button>
                <h2>Войти в систему</h2>
                <p>Выберите метод входа</p>

                {/* Steam login button */}
                <button className="login-button steam" onClick={handleSteamLogin} disabled={isLoading}>
                    <img src="images/steam.png" alt="Steam Logo" className="login-icon" />
                    {isLoading ? 'Logging in with Steam...' : 'Login with Steam'}
                </button>

                {/* Google login button */}
                <button className="login-button google" onClick={handleGoogleLogin} disabled={isLoading}>
                    <img src="images/google.png" alt="Google Logo" className="login-icon" />
                    {isLoading ? 'Logging in with Google...' : 'Login with Google'}
                </button>

                  {/* VK login button */}
                  <button className="login-button vk" onClick={handleVKLogin} disabled={isLoading}>
                    <img src="images/vk.png" alt="VK Logo" className="login-icon" />
                    {isLoading ? 'Logging in with VK...' : 'Login with VK'}
                </button>

                {/* Error message if any */}
                {error && <div className="error-message">{error}</div>}
            </div>
        </div>
    );
};

export default LoginModal;
