// src/components/MainImage.js
import React from 'react';

const MainImage = () => {
    return (
        <div className="main-image">
            <img src="/images/background3.webp" alt="" />
        </div>
    );
};

export default MainImage;
